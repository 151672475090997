import React from "react"
import {Helmet} from "react-helmet";

export default function Layout({children}) {
    return (
        <>
            <Helmet>
                <meta property="og:image" content="https://designatives.com/twitter-image.png"/>
                <meta name="twitter:image" content="https://designatives.com/twitter-image.png"/>
            </Helmet>
            <div>
                {children}
            </div>
        </>
    )
}
