import * as React from "react"
import { Link } from "gatsby"
import {Helmet} from "react-helmet";
import Header from "../components/header";
import Layout from "../components/layout";

import image404 from "../images/404.svg";

// markup
const NotFoundPage = () => {
  return (
      <main>
        <Helmet>
          <title>Designatives - 404</title>
        </Helmet>
        <Header/>
        <Layout>
          <section className="">
              <div className="container text-center">
                  <div className="row align-items-center vh-100">
                    <div className={'col-12 px-5'}>
                      <img src={image404} alt="404"/>
                        <h4>Ooops, Something went wrong</h4>
                    </div>
                  </div>
              </div>
          </section>
        </Layout>
      </main>
  )
}

export default NotFoundPage
